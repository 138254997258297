import { render, staticRenderFns } from "./MBFProducts.vue?vue&type=template&id=182b1f6a"
import script from "./MBFProducts.vue?vue&type=script&lang=js"
export * from "./MBFProducts.vue?vue&type=script&lang=js"
import style0 from "./MBFProducts.vue?vue&type=style&index=0&id=182b1f6a&prod&lang=css"
import style1 from "./MBFProducts.vue?vue&type=style&index=1&id=182b1f6a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports